import { useState } from 'react';

import './styles/mobile-features.scss';
import TwoByTwo from './partials/two-by-two';

type MobileFeaturesProps = {
  buttons: [{
    desktopScreenshot: string
    desktop_screenshot: {
      source_url: string
    }
  }],
  className: string
  sub_title: string
  title: string
}

const MobileFeatures  = ({ title, sub_title, buttons, className }:MobileFeaturesProps) => {
  const [activeFeatureIndex, setActiveFeatureIndex] = useState(0);

  // Need to convert buttons to array because acf repeater functionality is too expensive
  const buttonsArr = Object.values(buttons);

  return (
    <section className={ `mobile-features-section ${className ?? ''}` }>
      <div className="interactive-content">
        <header className="section-header">
          <h2 className="subheadline">{sub_title}</h2>
          <h3 className="headline">{title}</h3>
        </header>

        <TwoByTwo
          features={ buttonsArr }
          activeFeatureIndex={ activeFeatureIndex }
          setActiveFeatureIndex={ setActiveFeatureIndex }
        />
      </div>
      <div className="desktop-phone">
        <img
          loading="lazy"
          src="https://marketing-assets.wheniwork-production.com/2019/10/18132112/Mobile.png"
          alt="mobile phone"
          width="412"
          height="807"
        />
        <img
          className="phone-screen"
          loading="lazy"
          src={
            buttonsArr[activeFeatureIndex].desktopScreenshot ||
            buttonsArr[activeFeatureIndex].desktop_screenshot.source_url
          }
          alt="mobile phone screen"
        />
      </div>
    </section>
  );
};

export default MobileFeatures;
