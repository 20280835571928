
import CustomCalendar from 'components/modal/custom-calendar';
import SchedulingSignup from 'components/modal/signups';

import 'components/content-blocks/styles/basic-container.scss';

type BasicContainerProps = {
    button_text: string,
    className: string;
    copy: string;
    copy_2: string,
    demo_url: string;
    desktop_image: {
        id: string;
        source_url: string;
    },
    hideMobile: boolean,
    lightMode: boolean,
    mobile_image: {
        id: string;
        source_url: string;
    };
    title: string,
    uniqueId: string
}

export const BasicContainer = ({
  title,
  copy,
  copy_2,
  demo_url,
  className,
  uniqueId,
  button_text,
  desktop_image,
  mobile_image,
  lightMode, 
  hideMobile,
}: BasicContainerProps) => {
  const trialButton = <SchedulingSignup
    uniqueId= { uniqueId }
    fieldLayout="row"
    buttonClasses="button is-signup-button is-rounded"
    showModalButton
    signupModalLaunch={ button_text }
  />;
    
  const redirectModal = <CustomCalendar customCalendarUrl={ demo_url } buttonModalLaunchText="Book a Demo" />;
    
  return (<section className={ `section basic-container-section ${className || ''}` }>
    <div className={ `container ${lightMode ? 'lightMode' : ''}` }>
      <h2 className="headline">{title}</h2>
      <div className={ `copy ${hideMobile ? 'is-hidden-mobile' : ''}` }>
        {copy && <p>{copy}</p>}
        {copy_2 && <p>{copy_2}</p>}
      </div>
      {demo_url && demo_url ? redirectModal : trialButton}
      {desktop_image && <picture className="hero-image">
        <source
          srcSet={ desktop_image?.source_url }
          media="(min-width: 769px)"
        />
        <img
          src={ mobile_image?.source_url }
          alt="When I Work"
        />
      </picture>}
    </div>
  </section>);
};