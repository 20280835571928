import { useState } from 'react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import Wysiwyg from 'components/content-blocks/partials/wysiwyg';
import Link from 'components/link/link';
import Slider from 'components/sliders/slider';

import 'components/content-blocks/styles/customer-stories.scss';

const CustomerStories = ({
  hasNoIndustryTitles,
  section,
  card_section_title,
  card_groups,
  usingActiveIndustryIndex,
  notSwipeable,
}) => {
  const breakpoints = useBreakpoint();
  const [activeIndustryIndex, setActiveIndustryIndex] = useState(0);
  const card = [];
  const newObj = { card };

  card_groups.map(group => {
    card.push(...group.card);
  });

  const IndustryButtonsRenderText = () => (
    <p className="industry-button">{card[activeIndustryIndex].industry}</p>
  );

  const IndustryButtons = () =>
    card_groups.map((category, i) => {
      const selected = i === activeIndustryIndex;
      return (
        <button
          key={ `industry-button-${i}` }
          data-section="customer stories menu button"
          className={ `industry-button ${
            i === activeIndustryIndex ? 'is-active' : ''
          }` }
          selected={ selected }
          onClick={ () => {
            setActiveIndustryIndex(i);
          } }
        >
          {category.title}
          <div
            className={ `triangle ${
              i === activeIndustryIndex ? 'is-active' : ''
            }` }
          />
        </button>
      );
    });

  return (
    <section
      className="section customer-stories-section"
      data-section="customer stories"
    >
      <div className="customer-stories-header has-text-centered">
        <h2 className="sub-title">{section.sub_title}</h2>
        <h3 className="title">{section.title}</h3>
        <Wysiwyg wysiwygClasses="copy" content={ section.copy } />
      </div>
      <div
        className="customer-stories-container"
        data-section="customer stories"
      >
        <h4 className="customer-stories-title has-text-centered">
          {card_section_title}
        </h4>
        {!Boolean(hasNoIndustryTitles) && (
          <div className="industries">
            {breakpoints.sm ? (
              <IndustryButtonsRenderText />
            ) : (
              <IndustryButtons />
            )}
            <Link to="/industries" className="industry-button is-hidden-mobile">
              View All Industries
              <div className="triangle is-hidden-mobile" />
            </Link>
          </div>
        )}
        <div
          className="customer-stories-cards"
          data-section="customerStoriesCard"
        >
          {breakpoints.sm ? (
            <div className="industry">
              <Slider
                { ...newObj }
                setActiveIndustryIndex={ setActiveIndustryIndex }
                activeIndustryIndex={ activeIndustryIndex }
                usingActiveIndustryIndex={ usingActiveIndustryIndex }
                hasCta
                hasSubTitle
                hasTitle
                hasImage
                notSwipeable={ notSwipeable }
              />
              <div className="all-industries-container">
                <Link
                  to="/industries"
                  className="all-industries-button cta-button"
                >
                  View All Industries
                </Link>
              </div>
            </div>
          ) : (
            card_groups.map((category, i) => (
              <div key={ `industry-slider-${i}` } className="industry">
                <Slider
                  { ...category }
                  className={ `${i === activeIndustryIndex ? '' : 'is-hidden'}` }
                  hasCta
                  hasSubTitle
                  hasTitle
                  hasImage
                />
              </div>
            ))
          )}
        </div>
        <img
          loading="lazy"
          className="cloud cloud-one is-hidden-mobile"
          src="https://marketing-assets.wheniwork-production.com/2019/10/28154945/Cloud-1.svg"
          alt=""
        />
        <img
          loading="lazy"
          className="cloud cloud-two is-hidden-mobile"
          src="https://marketing-assets.wheniwork-production.com/2019/10/28154947/Cloud-2.svg"
          alt=""
        />
        <img
          loading="lazy"
          className="cloud cloud-three is-hidden-mobile"
          src="https://marketing-assets.wheniwork-production.com/2019/10/28154949/Cloud-3.svg"
          alt=""
        />
        <img
          loading="lazy"
          className="cloud cloud-four is-hidden-mobile"
          src="https://marketing-assets.wheniwork-production.com/2019/10/28154950/Cloud-4.svg"
          alt=""
        />
        <img
          loading="lazy"
          className="cloud cloud-five is-hidden-mobile"
          src="https://marketing-assets.wheniwork-production.com/2019/10/28154950/Cloud-5.svg"
          alt=""
        />
        <img
          loading="lazy"
          className="cloud cloud-six is-hidden-mobile"
          src="https://marketing-assets.wheniwork-production.com/2019/10/28154950/Cloud-6.svg"
          alt=""
        />
      </div>
      <div className="city-image-container">
        <img
          loading="lazy"
          className="city-image"
          src="https://marketing-assets.wheniwork-production.com/2019/10/22104940/Cityscape-Brown.png"
          alt="illustration of city skyline"
        />
      </div>
      <svg
        className="rounded-background"
        preserveAspectRatio="none"
        viewBox="0 0 1440 1180"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          id="Homepage"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            transform="translate(0.000000, -6579.000000)"
            fill="#FFF5D9"
            id="Testimonials"
          >
            <g transform="translate(-891.000000, 6579.000000)">
              <g id="Group-18">
                <ellipse
                  id="Oval"
                  cx="1611"
                  cy="590"
                  rx="1100"
                  ry="590"
                ></ellipse>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </section>
  );
};

export default CustomerStories;
