import { useEffect, useRef, useState } from 'react';

import './styles/customer-logos.scss';

const Customer = ({ numOfLogoComponents, sourceUrl, numLogos }) => {
  const createLogoContainer = (numOfLogoComponents, numLogos) => {
    const customerLogo = [];
    const logosPerGroup = Math.floor(numLogos / numOfLogoComponents);
    let staggerDelay = 4000;
    let highestIndex = 0;
    for (let i = 0; i < numOfLogoComponents; i++) {
      customerLogo.push(
        <LogoRotator
          key={ i }
          initialIndex={ highestIndex }
          staggerDelay={ staggerDelay }
          sourceUrl={ sourceUrl }
          logosPerGroup={ logosPerGroup }
        />
      );
      highestIndex = highestIndex + logosPerGroup;
      staggerDelay = staggerDelay + 1000;
    }
    return customerLogo;
  };

  return (
    <div className="customer-logos-container">
      {createLogoContainer(numOfLogoComponents, numLogos)}
    </div>
  );
};

const LogoRotator = ({ initialIndex, staggerDelay, sourceUrl, logosPerGroup }) => {
  const [imageIndex, setImageIndex] = useState(initialIndex);
  const i = initialIndex;
  const logo = Array.from(Array(logosPerGroup).keys());

  function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        const id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }
  useInterval(() => {
    const limit = initialIndex + (logosPerGroup - 1);
    if (imageIndex < limit) {
      setImageIndex(imageIndex + 1);
    } else {
      setImageIndex(initialIndex);
    }
  }, staggerDelay);
  return (
    <div className="customer-logo">
      {logo.map((value, index) => {
        return (
          <span
            key={ index }
            className={ `company logo${
              value + i === imageIndex ? ' active' : ''
            }` }
            style={ { 
              backgroundImage: `url(${sourceUrl})`,
              backgroundPositionY: `${((value + i) * 100 + 20) * -1}px`, // position of the logo in the gif * logo height + 20px for initial offset
            } }
          ></span>
        );
      })}
    </div>
  );
};

export default Customer;
