import './styles/integrations-info.scss';
import Link from "../link/link";

type Props = {
  computer_image_desktop: Image,
  computer_image_mobile: Image,
  copy: string,
  curve_image: Image,
  sub_title: string,
  title: string
}

type Image ={
  source_url: string
}


const IntegrationsInfo = (props:Props) => {
  return (
    <section className="integrations-info-section">
      <header>
        <h2 className="subheadline">{props.title}</h2>
        <h3 className="headline">{props.sub_title}</h3>
        <p className="has-text-centered">{props.copy}</p>
      </header>

      <picture className="computer-giraphix">
        <source
          className="computer-giraphix-mobile"
          srcSet={ props.computer_image_mobile.source_url }
          media="(max-width: 768px)"
        />
        <img
          loading="lazy"
          className="computer-giraphix-desktop"
          src={ props.computer_image_desktop.source_url }
          alt="Laptop with When I Work scheduler open and API icons"
          width="1224"
          height="384"
        />
      </picture>

      <div className="curve-giraphix">
        <img
          loading="lazy"
          src={ props.curve_image.source_url }
          alt="stylized background curve"
        />
      </div>

      <Link to="/integrations" className="view-all-integrations">
            View All Integrations
      </Link>
    </section>
  );
};

export default IntegrationsInfo;
