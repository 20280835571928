import Slider from 'components/sliders/slider';

import './styles/pr-awards.scss';

type PRAwardsProps = {
  awards: {
    award_image: string,
    award_text: string,
    award_url: string,
  }[],
  copy: string,
  sub_title: string,
  title: string,
}

const PRAwards = ({ awards, copy, sub_title, title }: PRAwardsProps) => {
  const cards = awards.map(award => {
    return {
      image: award.award_image,
      link_url: award.award_url,
      link_text: award.award_text,
    };
  });

  return (
    <section className="pr-awards-section section">
      <div className="container">
        <h2 className="sub-title">{ sub_title }</h2>
        <h3 className="headline">{ title }</h3>
        <p className="copy">{ copy }</p>
        <Slider
          { ...{ startingIndex: 0, activeIndex: 0, modalTitle: '', card: cards } }
          hasCopy
          hasImage
          hasCta
          sliderStyle={ { padding: '0 calc(50% - 115px)' } }
        />
      </div>
    </section>
  );
};

export default PRAwards;
