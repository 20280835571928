import ACFImage from 'types/ACFImage';
import ACFSignup from 'types/ACFSignup';

import Logos from 'components/content-blocks/partials/customer-logos';
import Signups from 'components/modal/signups';

import './styles/home.scss';

type HeroHomeProps = {
  background_desktop_image: ACFImage;
  image_height: number,
  image_width: number,
  num_logos: number,
  signup: ACFSignup;
  source_url: string;
  sub_title: string;
  title: string;
  trialLength: number
};

export const HeroHome = ({
  background_desktop_image,
  image_height,
  image_width,
  num_logos,
  signup,
  source_url,
  sub_title,
  title,
  trialLength,
}:HeroHomeProps) => {

  return (
    <section className="section home-hero-section" data-section="homepage-hero">
      <div className="hero-main">
        <div className="hero-left">
          <h1 className="subheadline">{sub_title}</h1>
          <h2 className="headline hero-headline">{title}</h2>
          <div className="signup-cta-container">
            <Signups
              signupModalLaunch={ signup.launch_modal_button_text }
              signupModalSubmit={ signup.modal_submit_button_text }
              fieldLayout="row"
              legalText={ signup.legal_text }
              showInitFields={ true }
              buttonInline={ true }
              uniqueId={ signup.unique_id }
              trialLength={ trialLength }
            />
          </div>
          <div className="customer-logos">
            <Logos
              numOfLogoComponents={ 4 }
              sourceUrl={ source_url }
              numLogos={ num_logos }
            />
          </div>
        </div>
        <div className="hero-image">
          <img
            src={ background_desktop_image.source_url }
            alt={ background_desktop_image.alt_text }
            width={ image_width }
            height={ image_height }
          />
        </div>
      </div>
    </section>
  );
};