import { graphql, useStaticQuery } from 'gatsby';

import Layout from 'layouts/default';

import { BasicContainer } from 'components/content-blocks/basic-container';
import IndustryCard from 'components/content-blocks/customer-feature-benefit';
import QuoteBox from 'components/content-blocks/customer-quote-box';
import CustomerStories from 'components/content-blocks/customer-stories';
import FeaturesGrid from 'components/content-blocks/features-grid';
import MobileFeatures from 'components/content-blocks/mobile-features';
import Products from 'components/content-blocks/products';
import Reviews from 'components/content-blocks/reviews';
import IntegrationsInfo from 'components/globals/integrations-info';
import PRAwards from 'components/globals/pr-awards';
import Yoast from 'components/globals/yoast';
import { HeroHome } from 'components/hero/home';

import './styles/homepage.scss';

export default function Home() {
  const data = useStaticQuery(graphql`
    {
      allWordpressPage(filter: { slug: { eq: "home" } }) {
        edges {
          node {
            wordpress_id
        yoast_head_json {
          title
          description
          canonical
          og_title
          og_description
          og_image {
            url {
              source_url
            }
          }
          og_url
          og_type
          twitter_title
          twitter_description
          twitter_image {
            source_url
          }
        }
            acf {
              hero {
                title
                sub_title
                signup {
                  unique_id
                  signup_cta_title
                  modal_title
                  modal_submit_button_text
                  legal_text
                  launch_modal_button_text
                  alert_text
                }
                background_desktop_image {
                  source_url
                  alt_text
                }
              }
              reviews_section {
                title
                sub_title
                copy
              }
              basic_title_subtitle_copy_image {
                copy
                sub_title
                title
                desktop_image {
                  source_url
                }
                mobile_image {
                  source_url
                }
              }
              basic_title_subtitle_copy_image_2 {
                title
                copy
                desktop_image {
                  id
                  source_url
                }
                mobile_image {
                  id
                  source_url
                }
              }
              customer_quote_1 {
                company_info
                desktop_image {
                  source_url
                }
                mobile_image {
                  source_url
                }
                quote
              }
              customer_stories {
                card_groups {
                  title
                  card {
                    card_link
                    card_link_title
                    sub_title
                    title
                    image {
                      alt_text
                      source_url
                    }
                    industry
                  }
                }
                section {
                  sub_title
                  title
                  copy
                }
                card_section_title
              }
              customer_feature_benefit {
                sub_title
                title
                copy
                customer {
                  business
                  name
                  photo_desktop {
                    source_url
                  }
                  photo_mobile {
                    source_url
                  }
                  since
                }
                stats {
                  value_1
                  descriptor_1
                  value_2
                  descriptor_2
                  value_3
                  descriptor_3
                }
              }
              features_grid_mobile_1 {
                title
                sub_title
                buttons {
                  button_1 {
                    icon_from_library
                    icon_width
                    title
                    body
                    desktop_screenshot {
                      source_url
                    }
                    mobile_screenshot {
                      source_url
                    }
                  }
                  button_2 {
                    icon_from_library
                    icon_width
                    title
                    body
                    desktop_screenshot {
                      source_url
                    }
                    mobile_screenshot {
                      source_url
                    }
                  }
                  button_3 {
                    icon_from_library
                    icon_width
                    title
                    body
                    desktop_screenshot {
                      source_url
                    }
                    mobile_screenshot {
                      source_url
                    }
                  }
                  button_4 {
                    icon_from_library
                    icon_width
                    title
                    body
                    desktop_screenshot {
                      source_url
                    }
                    mobile_screenshot {
                      source_url
                    }
                  }
                }
              }
              features_grid {
                title
                sub_title
                buttons {
                  title
                  body
                  alt_text
                  icon_width
                  icon
                  icon_from_library
                  mobile_screenshot {
                    source_url
                  }
                  desktop_screenshot {
                    source_url
                  }
                }
              }
              cards_1 {
                card {
                  header
                  image {
                    source_url
                  }
                  title
                  copy
                  link_url
                  link_text
                }
              }
            }
          }
        }
      }
      wordpressAcfOptions {
        options {
          integrations_info {
            title
            sub_title
            copy
            computer_image_desktop {
              source_url
            }
            computer_image_mobile {
              source_url
            }
            curve_image {
              source_url
            }
          }
          pr_awards {
            awards {
              award_text
              award_url
              award_image {
                source_url
              }
            }
            copy
            sub_title
            title
          }
        }
      }
    }
  `);
  
  const yoast = data.allWordpressPage.edges[0].node.yoast_head_json;
  const pageContext = data.allWordpressPage.edges[0].node.acf;
  const hero = pageContext.hero;
  const products = pageContext.cards_1;
  const mobileFeatures = pageContext.features_grid_mobile_1;
  const customerQuote = pageContext.customer_quote_1;
  const reviews = pageContext.reviews_section;
  const customerCard = pageContext.customer_feature_benefit;
  const featuresGrid = pageContext.features_grid;
  const customerStories = pageContext.customer_stories;
  const integrationsInfo = data.wordpressAcfOptions.options.integrations_info;
  const prAwards = data.wordpressAcfOptions.options.pr_awards;
  const allInOne = pageContext.basic_title_subtitle_copy_image_2;

  return (
    <Layout legalText={ hero.signup.legal_text }>
      <Yoast { ...yoast } canonical={ `https://${process.env.GATSBY_SITE_URL === 'wheniwork.net' ? 'wheniwork.com' : process.env.GATSBY_SITE_URL}` } />
      <div className="home-page-container" data-signal={ data.allWordpressPage.edges[0].node.wordpress_id }>
        <HeroHome
          image_height={ 469 }
          image_width={ 782 }
          num_logos={ 28 }
          source_url="https://marketing-assets.wheniwork-production.com/2019/10/14154206/sprite_customers_trans.png"
          { ...hero }
        />
        <Products
          { ...products }
          appScreenShot={ pageContext.basic_title_subtitle_copy_image }
        />
        <IndustryCard { ...customerCard[0] } />
        <MobileFeatures { ...mobileFeatures } />
        <IndustryCard { ...customerCard[1] } className="reverse" />
        <FeaturesGrid { ...featuresGrid[0] } className="reverse" />
        <QuoteBox { ...customerQuote } />
        <FeaturesGrid { ...featuresGrid[1] } />
        <PRAwards { ...prAwards } />
        <CustomerStories { ...customerStories } usingActiveIndustryIndex />
        <BasicContainer { ...allInOne } lightMode />
        <Reviews { ...reviews } />
        <IntegrationsInfo { ...integrationsInfo } />
      </div>
    </Layout>
  );
}
